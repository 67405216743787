@font-face {
font-family: '__mbFont_dff96b';
src: url(/_next/static/media/9e9eb99ef11b6869-s.p.woff2) format('woff2');
font-display: optional;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__mbFont_dff96b';
src: url(/_next/static/media/899b396261bea2f3-s.p.woff2) format('woff2');
font-display: optional;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__mbFont_Fallback_dff96b';src: local("Arial");ascent-override: 101.01%;descent-override: 33.54%;line-gap-override: 0.00%;size-adjust: 99.00%
}.__className_dff96b {font-family: '__mbFont_dff96b', '__mbFont_Fallback_dff96b'
}.__variable_dff96b {--font-sans: '__mbFont_dff96b', '__mbFont_Fallback_dff96b'
}


  /*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

  *,
  ::before,
  ::after {
    box-sizing: border-box; /* 1 */
    border-width: 0; /* 2 */
    border-style: solid; /* 2 */
    border-color: currentColor; /* 2 */
  }

  ::before,
  ::after {
    --tw-content: '';
  }

  /*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
5. Use the user's configured `sans` font-feature-settings by default.
6. Use the user's configured `sans` font-variation-settings by default.
*/

  html {
    font-family: var(--font-sans); /* 4 */

    line-height: 1.5; /* 1 */ /* 3 */
    -moz-tab-size: 4;
      -o-tab-size: 4;
         tab-size: 4; /* 3 */
    -webkit-text-size-adjust: 100%;
       -moz-text-size-adjust: 100%;
            text-size-adjust: 100%; /* 2 */
    font-feature-settings: normal; /* 5 */
    font-variation-settings: normal; /* 6 */
  }

  /*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

  body {
    margin: 0; /* 1 */
    line-height: inherit; /* 2 */
  }

  /*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/

  hr {
    height: 0; /* 1 */
    border-top-width: 1px; /* 3 */
    color: inherit; /* 2 */
  }

  /*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

  abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
  }

  /*
Remove the default font size and weight for headings.
*/

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  /*
Reset links to optimize for opt-in styling instead of opt-out.
*/

  a {
    color: inherit;
    text-decoration: inherit;
  }

  /*
Add the correct font weight in Edge and Safari.
*/

  b,
  strong {
    font-weight: bolder;
  }

  /*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/

  code,
  kbd,
  samp,
  pre {
    font-size: 1em; /* 2 */
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace; /* 1 */
  }

  /*
Add the correct font size in all browsers.
*/

  small {
    font-size: 80%;
  }

  /*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

  sub,
  sup {
    position: relative;
    vertical-align: baseline;
    font-size: 75%;
    line-height: 0;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  /*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/

  table {
    border-collapse: collapse; /* 3 */
    border-color: inherit; /* 2 */
    text-indent: 0; /* 1 */
  }

  /*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0; /* 2 */
    padding: 0; /* 3 */
    color: inherit; /* 1 */
    font-weight: inherit; /* 1 */
    font-size: 100%; /* 1 */
    font-family: inherit; /* 1 */
    line-height: inherit; /* 1 */
  }

  /*
Remove the inheritance of text transform in Edge and Firefox.
*/

  button,
  select {
    text-transform: none;
  }

  /*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/

  button {
    background-color: transparent; /* 2 */
    background-image: none; /* 2 */
    -webkit-appearance: button;
       -moz-appearance: button;
            appearance: button; /* 1 */
  }

  /*
Use the modern Firefox focus style for all focusable elements.
*/

  :-moz-focusring {
    outline: auto;
  }

  /*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

  :-moz-ui-invalid {
    box-shadow: none;
  }

  /*
Add the correct vertical alignment in Chrome and Firefox.
*/

  progress {
    vertical-align: baseline;
  }

  /*
Correct the cursor style of increment and decrement buttons in Safari.
*/

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  /*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

  [type='search'] {
    outline-offset: -2px; /* 2 */
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield; /* 1 */
  }

  /*
Remove the inner padding in Chrome and Safari on macOS.
*/

  ::-webkit-search-decoration {
    -webkit-appearance: none;
            appearance: none;
  }

  /*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

  ::-webkit-file-upload-button {
    font: inherit; /* 2 */
    -webkit-appearance: button;
            appearance: button; /* 1 */
  }

  /*
Add the correct display in Chrome and Safari.
*/

  summary {
    display: list-item;
  }

  /*
Removes the default spacing and border for appropriate elements.
*/

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  legend {
    padding: 0;
  }

  ol,
  ul,
  menu {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  /*
Prevent resizing textareas horizontally by default.
*/

  textarea {
    resize: vertical;
  }

  /*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

  input::-moz-placeholder, textarea::-moz-placeholder {
    color: #9ca3af; /* 2 */
    opacity: 1; /* 1 */
  }

  input::placeholder,
  textarea::placeholder {
    color: #9ca3af; /* 2 */
    opacity: 1; /* 1 */
  }

  /*
Set the default cursor for buttons.
*/

  button,
  [role='button'] {
    cursor: pointer;
  }

  /*
Make sure disabled buttons don't get the pointer cursor.
*/
  :disabled {
    cursor: default;
  }

  /*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block; /* 1 */
    vertical-align: middle; /* 2 */
  }

  /*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  /* Make elements with the HTML hidden attribute stay hidden by default */
  [hidden] {
    display: none;
  }
  :root {
    --admin-grid-gap: 0.9375rem;
    --admin-outer: 5vw;
    --h-top-navigation: 76px;
    --h-admin-main: calc(100vh - var(--h-top-navigation));
  }

  *:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

  *:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
  @media (min-width: 1280px) {
    :root {
      --admin-grid-gap: 1.25rem;
      --admin-outer: min(8vw, 200px);
    }
  }
  @media (width >= 1440px) {
    :root {
      --admin-grid-gap: 1.875rem;
    }
  }
  @media (width >= 1960px) {
    :root {
      --admin-grid-gap: 2.5rem;
    }
  }
  body {
    /* stylelint-disable-next-line function-no-unknown */
    --swiper-pagination-color: #F3F3F4;
  }

*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}

/* Use a custom preflight to prevent css specificity collisions with css modules. */

/* stylelint-disable */

/* stylelint-enable */

.container {
    width: 100%;
    padding-right: min(4vw, 60px);
    padding-left: min(4vw, 60px);
}

@media (min-width: calc(1232px + 2 * min(4vw, 60px))) {

    .container {
        max-width: calc(1232px + 2 * min(4vw, 60px));
    }
}

.card {
    border-radius: 0.5rem;
    padding: 1px;

    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
}

.pointer-events-none {
    pointer-events: none;
}

.pointer-events-auto {
    pointer-events: auto;
}

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.inset-0 {
    inset: 0px;
}

.inset-x-16 {
    left: 1rem;
    right: 1rem;
}

.inset-y-0 {
    top: 0px;
    bottom: 0px;
}

.-left-8 {
    left: -0.5rem;
}

.bottom-0 {
    bottom: 0px;
}

.bottom-192 {
    bottom: 12rem;
}

.bottom-24 {
    bottom: 1.5rem;
}

.bottom-96 {
    bottom: 6rem;
}

.bottom-\[8px\] {
    bottom: 8px;
}

.left-0 {
    left: 0px;
}

.left-1\/2 {
    left: 50%;
}

.left-20 {
    left: 1.25rem;
}

.left-\[-10px\] {
    left: -10px;
}

.left-\[-6px\] {
    left: -6px;
}

.left-\[10px\] {
    left: 10px;
}

.left-\[13px\] {
    left: 13px;
}

.left-\[30px\] {
    left: 30px;
}

.left-\[8px\] {
    left: 8px;
}

.left-full {
    left: 100%;
}

.right-0 {
    right: 0px;
}

.right-16 {
    right: 1rem;
}

.right-20 {
    right: 1.25rem;
}

.right-24 {
    right: 1.5rem;
}

.right-8 {
    right: 0.5rem;
}

.right-\[-12px\] {
    right: -12px;
}

.right-\[10px\] {
    right: 10px;
}

.top-0 {
    top: 0px;
}

.top-1\/2 {
    top: 50%;
}

.top-20 {
    top: 1.25rem;
}

.top-24 {
    top: 1.5rem;
}

.top-8 {
    top: 0.5rem;
}

.top-\[-3px\] {
    top: -3px;
}

.top-\[-7px\] {
    top: -7px;
}

.top-\[14px\] {
    top: 14px;
}

.top-full {
    top: 100%;
}

.isolate {
    isolation: isolate;
}

.-z-10 {
    z-index: -10;
}

.-z-50 {
    z-index: -50;
}

.z-10 {
    z-index: 10;
}

.z-20 {
    z-index: 20;
}

.z-30 {
    z-index: 30;
}

.z-\[1\] {
    z-index: 1;
}

.z-auto {
    z-index: auto;
}

.col-span-12 {
    grid-column: span 12 / span 12;
}

.col-span-2 {
    grid-column: span 2 / span 2;
}

.col-span-3 {
    grid-column: span 3 / span 3;
}

.col-span-6 {
    grid-column: span 6 / span 6;
}

.col-span-full {
    grid-column: 1 / -1;
}

.col-start-1 {
    grid-column-start: 1;
}

.row-start-1 {
    grid-row-start: 1;
}

.m-16 {
    margin: 1rem;
}

.m-24 {
    margin: 1.5rem;
}

.m-auto {
    margin: auto;
}

.-mx-16 {
    margin-left: -1rem;
    margin-right: -1rem;
}

.mx-20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}

.mx-24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.mx-\[-10px\] {
    margin-left: -10px;
    margin-right: -10px;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.my-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.my-32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.my-\[-4px\] {
    margin-top: -4px;
    margin-bottom: -4px;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.-ml-8 {
    margin-left: -0.5rem;
}

.-mt-8 {
    margin-top: -0.5rem;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-16 {
    margin-bottom: 1rem;
}

.mb-20 {
    margin-bottom: 1.25rem;
}

.mb-24 {
    margin-bottom: 1.5rem;
}

.mb-32 {
    margin-bottom: 2rem;
}

.mb-4 {
    margin-bottom: 0.25rem;
}

.mb-48 {
    margin-bottom: 3rem;
}

.mb-8 {
    margin-bottom: 0.5rem;
}

.mb-\[6px\] {
    margin-bottom: 6px;
}

.mb-auto {
    margin-bottom: auto;
}

.ml-12 {
    margin-left: 12px;
}

.ml-16 {
    margin-left: 1rem;
}

.ml-20 {
    margin-left: 1.25rem;
}

.ml-4 {
    margin-left: 0.25rem;
}

.ml-64 {
    margin-left: 4rem;
}

.ml-8 {
    margin-left: 0.5rem;
}

.ml-88 {
    margin-left: 5.5rem;
}

.ml-\[40px\] {
    margin-left: 40px;
}

.ml-\[500px\] {
    margin-left: 500px;
}

.ml-auto {
    margin-left: auto;
}

.mr-12 {
    margin-right: 12px;
}

.mr-16 {
    margin-right: 1rem;
}

.mr-20 {
    margin-right: 1.25rem;
}

.mr-24 {
    margin-right: 1.5rem;
}

.mr-48 {
    margin-right: 3rem;
}

.mr-\[-5px\] {
    margin-right: -5px;
}

.mr-auto {
    margin-right: auto;
}

.mt-1 {
    margin-top: 1px;
}

.mt-12 {
    margin-top: 12px;
}

.mt-16 {
    margin-top: 1rem;
}

.mt-20 {
    margin-top: 1.25rem;
}

.mt-24 {
    margin-top: 1.5rem;
}

.mt-32 {
    margin-top: 2rem;
}

.mt-4 {
    margin-top: 0.25rem;
}

.mt-48 {
    margin-top: 3rem;
}

.mt-64 {
    margin-top: 4rem;
}

.mt-72 {
    margin-top: 4.5rem;
}

.mt-8 {
    margin-top: 0.5rem;
}

.mt-\[-3px\] {
    margin-top: -3px;
}

.mt-\[26px\] {
    margin-top: 26px;
}

.mt-\[2px\] {
    margin-top: 2px;
}

.mt-\[500px\] {
    margin-top: 500px;
}

.mt-auto {
    margin-top: auto;
}

.box-content {
    box-sizing: content-box;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.table {
    display: table;
}

.grid {
    display: grid;
}

.contents {
    display: contents;
}

.hidden {
    display: none;
}

.aspect-square {
    aspect-ratio: 1 / 1;
}

.aspect-video {
    aspect-ratio: 16 / 9;
}

.size-16 {
    width: 1rem;
    height: 1rem;
}

.size-18 {
    width: 1.125rem;
    height: 1.125rem;
}

.size-20 {
    width: 1.25rem;
    height: 1.25rem;
}

.size-22 {
    width: 1.375rem;
    height: 1.375rem;
}

.size-24 {
    width: 1.5rem;
    height: 1.5rem;
}

.size-36 {
    width: 2.25em;
    height: 2.25em;
}

.size-40 {
    width: 2.5em;
    height: 2.5em;
}

.size-48 {
    width: 3rem;
    height: 3rem;
}

.size-72 {
    width: 4.5rem;
    height: 4.5rem;
}

.size-8 {
    width: 0.5rem;
    height: 0.5rem;
}

.size-\[168px\] {
    width: 168px;
    height: 168px;
}

.size-full {
    width: 100%;
    height: 100%;
}

.h-0 {
    height: 0px;
}

.h-1 {
    height: 1px;
}

.h-1\/2 {
    height: 50%;
}

.h-16 {
    height: 1rem;
}

.h-18 {
    height: 18px;
}

.h-20 {
    height: 1.25rem;
}

.h-24 {
    height: 1.5rem;
}

.h-32 {
    height: 2rem;
}

.h-4 {
    height: 0.25rem;
}

.h-48 {
    height: 3rem;
}

.h-72 {
    height: 4.5rem;
}

.h-8 {
    height: 0.5rem;
}

.h-88 {
    height: 5.5rem;
}

.h-96 {
    height: 6rem;
}

.h-\[100px\] {
    height: 100px;
}

.h-\[10px\] {
    height: 10px;
}

.h-\[11px\] {
    height: 11px;
}

.h-\[120px\] {
    height: 120px;
}

.h-\[194px\] {
    height: 194px;
}

.h-\[200px\] {
    height: 200px;
}

.h-\[300px\] {
    height: 300px;
}

.h-\[30px\] {
    height: 30px;
}

.h-\[312px\] {
    height: 312px;
}

.h-\[34px\] {
    height: 34px;
}

.h-\[350px\] {
    height: 350px;
}

.h-\[400px\] {
    height: 400px;
}

.h-\[500px\] {
    height: 500px;
}

.h-\[6px\] {
    height: 6px;
}

.h-\[94px\] {
    height: 94px;
}

.h-\[95vh\] {
    height: 95vh;
}

.h-admin-main {
    height: var(--h-admin-main);
}

.h-full {
    height: 100%;
}

.h-screen {
    height: 100vh;
}

.h-top-navigation {
    height: var(--h-top-navigation);
}

.max-h-\[588px\] {
    max-height: 588px;
}

.max-h-\[calc\(100vh-250px\)\] {
    max-height: calc(100vh - 250px);
}

.min-h-\[2\.125rem\] {
    min-height: 2.125rem;
}

.min-h-\[300px\] {
    min-height: 300px;
}

.min-h-\[42px\] {
    min-height: 42px;
}

.min-h-\[500px\] {
    min-height: 500px;
}

.min-h-\[800px\] {
    min-height: 800px;
}

.min-h-\[80px\] {
    min-height: 80px;
}

.min-h-\[90px\] {
    min-height: 90px;
}

.min-h-\[calc\(4em\+22px\)\] {
    min-height: calc(4em + 22px);
}

.min-h-full {
    min-height: 100%;
}

.min-h-screen {
    min-height: 100vh;
}

.w-0 {
    width: 0px;
}

.w-1 {
    width: 1px;
}

.w-1\/2 {
    width: 50%;
}

.w-16 {
    width: 1rem;
}

.w-18 {
    width: 18px;
}

.w-20 {
    width: 1.25rem;
}

.w-24 {
    width: 1.5rem;
}

.w-32 {
    width: 2rem;
}

.w-4 {
    width: 0.25rem;
}

.w-4\/5 {
    width: 80%;
}

.w-48 {
    width: 3rem;
}

.w-5\/6 {
    width: 83.333333%;
}

.w-72 {
    width: 4.5rem;
}

.w-8 {
    width: 0.5rem;
}

.w-88 {
    width: 5.5rem;
}

.w-96 {
    width: 6rem;
}

.w-\[10px\] {
    width: 10px;
}

.w-\[115px\] {
    width: 115px;
}

.w-\[11px\] {
    width: 11px;
}

.w-\[120px\] {
    width: 120px;
}

.w-\[160px\] {
    width: 160px;
}

.w-\[180px\] {
    width: 180px;
}

.w-\[200px\] {
    width: 200px;
}

.w-\[220px\] {
    width: 220px;
}

.w-\[245px\] {
    width: 245px;
}

.w-\[274px\] {
    width: 274px;
}

.w-\[280px\] {
    width: 280px;
}

.w-\[300px\] {
    width: 300px;
}

.w-\[323px\] {
    width: 323px;
}

.w-\[34px\] {
    width: 34px;
}

.w-\[3px\] {
    width: 3px;
}

.w-\[400px\] {
    width: 400px;
}

.w-\[40vw\] {
    width: 40vw;
}

.w-\[44px\] {
    width: 44px;
}

.w-\[500px\] {
    width: 500px;
}

.w-\[550px\] {
    width: 550px;
}

.w-\[9px\] {
    width: 9px;
}

.w-full {
    width: 100%;
}

.min-w-18 {
    min-width: 18px;
}

.min-w-\[110px\] {
    min-width: 110px;
}

.min-w-\[267px\] {
    min-width: 267px;
}

.min-w-\[300px\] {
    min-width: 300px;
}

.min-w-\[400px\] {
    min-width: 400px;
}

.min-w-\[450px\] {
    min-width: 450px;
}

.min-w-\[540px\] {
    min-width: 540px;
}

.min-w-\[580px\] {
    min-width: 580px;
}

.min-w-min {
    min-width: -moz-min-content;
    min-width: min-content;
}

.max-w-2xl {
    max-width: 42rem;
}

.max-w-3xl {
    max-width: 48rem;
}

.max-w-4xl {
    max-width: 56rem;
}

.max-w-\[1340px\] {
    max-width: 1340px;
}

.max-w-\[300px\] {
    max-width: 300px;
}

.max-w-\[320px\] {
    max-width: 320px;
}

.max-w-\[33\%\] {
    max-width: 33%;
}

.max-w-full {
    max-width: 100%;
}

.max-w-xl {
    max-width: 36rem;
}

.flex-1 {
    flex: 1 1 0%;
}

.shrink-0 {
    flex-shrink: 0;
}

.grow {
    flex-grow: 1;
}

.origin-bottom {
    transform-origin: bottom;
}

.origin-left {
    transform-origin: left;
}

.-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-100 {
    --tw-scale-x: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}

.resize {
    resize: both;
}

.appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

.auto-cols-max {
    grid-auto-columns: max-content;
}

.grid-flow-col {
    grid-auto-flow: column;
}

.auto-rows-max {
    grid-auto-rows: max-content;
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-24 {
    grid-template-columns: repeat(24, minmax(0, 1fr));
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-\[repeat\(24\2c 1fr\)\] {
    grid-template-columns: repeat(24,1fr);
}

.grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
}

.flex-col {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.\!items-start {
    align-items: flex-start !important;
}

.items-start {
    align-items: flex-start;
}

.items-end {
    align-items: flex-end;
}

.items-center {
    align-items: center;
}

.items-baseline {
    align-items: baseline;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-stretch {
    justify-content: stretch;
}

.gap {
    gap: 40px;
}

.gap-0 {
    gap: 0px;
}

.gap-1 {
    gap: 1px;
}

.gap-12 {
    gap: 12px;
}

.gap-16 {
    gap: 1rem;
}

.gap-20 {
    gap: 1.25rem;
}

.gap-24 {
    gap: 1.5rem;
}

.gap-32 {
    gap: 2rem;
}

.gap-4 {
    gap: 0.25rem;
}

.gap-48 {
    gap: 3rem;
}

.gap-64 {
    gap: 4rem;
}

.gap-8 {
    gap: 0.5rem;
}

.gap-88 {
    gap: 5.5rem;
}

.gap-96 {
    gap: 6rem;
}

.gap-x-12 {
    -moz-column-gap: 12px;
         column-gap: 12px;
}

.gap-x-16 {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
}

.gap-x-20 {
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
}

.gap-x-24 {
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
}

.gap-x-32 {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
}

.gap-x-48 {
    -moz-column-gap: 3rem;
         column-gap: 3rem;
}

.gap-x-72 {
    -moz-column-gap: 4.5rem;
         column-gap: 4.5rem;
}

.gap-x-8 {
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
}

.gap-x-admin-grid {
    -moz-column-gap: var(--admin-grid-gap);
         column-gap: var(--admin-grid-gap);
}

.gap-y-12 {
    row-gap: 12px;
}

.gap-y-16 {
    row-gap: 1rem;
}

.gap-y-24 {
    row-gap: 1.5rem;
}

.gap-y-48 {
    row-gap: 3rem;
}

.gap-y-8 {
    row-gap: 0.5rem;
}

.space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.self-start {
    align-self: flex-start;
}

.self-stretch {
    align-self: stretch;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-auto {
    overflow-y: auto;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.rounded-10 {
    border-radius: 0.625rem;
}

.rounded-3 {
    border-radius: 0.1875rem;
}

.rounded-4 {
    border-radius: 0.25rem;
}

.rounded-8 {
    border-radius: 0.5rem;
}

.rounded-\[12px\] {
    border-radius: 12px;
}

.rounded-\[1px\] {
    border-radius: 1px;
}

.rounded-\[6px\] {
    border-radius: 6px;
}

.rounded-full {
    border-radius: 9999px;
}

.rounded-none {
    border-radius: 0px;
}

.rounded-b-10 {
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
}

.rounded-b-8 {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.rounded-b-\[21px\] {
    border-bottom-right-radius: 21px;
    border-bottom-left-radius: 21px;
}

.rounded-l-3 {
    border-top-left-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem;
}

.rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
}

.rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}

.rounded-t-8 {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.border {
    border-width: 1px;
}

.border-2 {
    border-width: 2px;
}

.border-\[10px\] {
    border-width: 10px;
}

.border-x {
    border-left-width: 1px;
    border-right-width: 1px;
}

.border-b {
    border-bottom-width: 1px;
}

.border-l {
    border-left-width: 1px;
}

.border-t {
    border-top-width: 1px;
}

.border-\[\#F7F7FA\] {
    --tw-border-opacity: 1;
    border-color: rgb(247 247 250 / var(--tw-border-opacity));
}

.border-greyblue-20 {
    --tw-border-opacity: 1;
    border-color: rgb(207 207 209 / var(--tw-border-opacity));
}

.border-greyblue-5 {
    --tw-border-opacity: 1;
    border-color: rgb(243 243 244 / var(--tw-border-opacity));
}

.border-greyblue-70 {
    --tw-border-opacity: 1;
    border-color: rgb(87 87 94 / var(--tw-border-opacity));
}

.border-greyblue-85 {
    --tw-border-opacity: 1;
    border-color: rgb(51 51 60 / var(--tw-border-opacity));
}

.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-x-greyblue-20 {
    --tw-border-opacity: 1;
    border-left-color: rgb(207 207 209 / var(--tw-border-opacity));
    border-right-color: rgb(207 207 209 / var(--tw-border-opacity));
}

.border-b-greyblue-20 {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(207 207 209 / var(--tw-border-opacity));
}

.border-l-greyblue-20 {
    --tw-border-opacity: 1;
    border-left-color: rgb(207 207 209 / var(--tw-border-opacity));
}

.border-t-greyblue-70 {
    --tw-border-opacity: 1;
    border-top-color: rgb(87 87 94 / var(--tw-border-opacity));
}

.bg-\[\#F7F7FA\] {
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 250 / var(--tw-bg-opacity));
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/10 {
    background-color: rgb(0 0 0 / 0.1);
}

.bg-error {
    --tw-bg-opacity: 1;
    background-color: rgb(237 67 43 / var(--tw-bg-opacity));
}

.bg-greyblue-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 15 25 / var(--tw-bg-opacity));
}

.bg-greyblue-20 {
    --tw-bg-opacity: 1;
    background-color: rgb(207 207 209 / var(--tw-bg-opacity));
}

.bg-greyblue-30 {
    --tw-bg-opacity: 1;
    background-color: rgb(183 183 186 / var(--tw-bg-opacity));
}

.bg-greyblue-40 {
    --tw-bg-opacity: 1;
    background-color: rgb(159 159 163 / var(--tw-bg-opacity));
}

.bg-greyblue-5 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 243 244 / var(--tw-bg-opacity));
}

.bg-greyblue-55 {
    --tw-bg-opacity: 1;
    background-color: rgb(124 124 129 / var(--tw-bg-opacity));
}

.bg-greyblue-70 {
    --tw-bg-opacity: 1;
    background-color: rgb(87 87 94 / var(--tw-bg-opacity));
}

.bg-greyblue-85 {
    --tw-bg-opacity: 1;
    background-color: rgb(51 51 60 / var(--tw-bg-opacity));
}

.bg-greyblue-90 {
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 48 / var(--tw-bg-opacity));
}

.bg-greyblue-95 {
    --tw-bg-opacity: 1;
    background-color: rgb(28 28 37 / var(--tw-bg-opacity));
}

.bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(68 128 240 / var(--tw-bg-opacity));
}

.bg-transparent {
    background-color: transparent;
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gradient-iri {
    background-image: linear-gradient(to top, #25B789,#49C288,#77CF86,#96D885,#BCE283,#CBDF81,#DADD7F,#ECE584,#FCEC88,#FED980,#FDC477,#FCB975,#FDB074,#FE9E71,#FD886C,#F77B5E,#F06E4F,#E9603F,#E25330);
}

.bg-gradient-iri-to-right {
    background-image: linear-gradient(to right, #25B789,#49C288,#77CF86,#96D885,#BCE283,#CBDF81,#DADD7F,#ECE584,#FCEC88,#FED980,#FDC477,#FCB975,#FDB074,#FE9E71,#FD886C,#F77B5E,#F06E4F,#E9603F,#E25330);
}

.bg-gradient-occupancy-to-right {
    background-image: linear-gradient(to right, #25B789 50%, #BCE283 65%, #FCEC88 80%, #FD886C 95%, #E25330 100%);
}

.bg-gradient-risk-score-to-right {
    background-image: linear-gradient(to right, #25B789 0%,#77CF86 11%,#BCE283 22%,#DADD7F 33%,#FCEC88 44%,#FDC477 56%,#FDB074 67%,#FD886C 78%,#F06E4F 89%,#E25330 100%);
}

.bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-\[\#E5F7FD\] {
    --tw-gradient-from: #E5F7FD var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(229 247 253 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gradient-end {
    --tw-gradient-from: #6468E7 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(100 104 231 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gradient-start {
    --tw-gradient-from: #00ADED var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 173 237 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-greyblue-90 {
    --tw-gradient-from: #272730 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(39 39 48 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-5\% {
    --tw-gradient-from-position: 5%;
}

.to-\[\#01B0F0\] {
    --tw-gradient-to: #01B0F0 var(--tw-gradient-to-position);
}

.to-gradient-end {
    --tw-gradient-to: #6468E7 var(--tw-gradient-to-position);
}

.to-gradient-start {
    --tw-gradient-to: #00ADED var(--tw-gradient-to-position);
}

.to-transparent {
    --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.to-90\% {
    --tw-gradient-to-position: 90%;
}

.bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
}

.fill-current {
    fill: currentColor;
}

.fill-white {
    fill: #fff;
}

.object-cover {
    -o-object-fit: cover;
       object-fit: cover;
}

.p-1 {
    padding: 1px;
}

.p-12 {
    padding: 12px;
}

.p-16 {
    padding: 1rem;
}

.p-20 {
    padding: 1.25rem;
}

.p-24 {
    padding: 1.5rem;
}

.p-32 {
    padding: 2rem;
}

.p-4 {
    padding: 0.25rem;
}

.p-48 {
    padding: 3rem;
}

.p-64 {
    padding: 4rem;
}

.p-8 {
    padding: 0.5rem;
}

.p-96 {
    padding: 6rem;
}

.p-\[3px\] {
    padding: 3px;
}

.p-\[5px\] {
    padding: 5px;
}

.px-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.px-16 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.px-20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.px-24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.px-32 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.px-48 {
    padding-left: 3rem;
    padding-right: 3rem;
}

.px-64 {
    padding-left: 4rem;
    padding-right: 4rem;
}

.px-96 {
    padding-left: 6rem;
    padding-right: 6rem;
}

.px-\[42px\] {
    padding-left: 42px;
    padding-right: 42px;
}

.px-admin-outer {
    padding-left: var(--admin-outer);
    padding-right: var(--admin-outer);
}

.py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.py-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.py-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.py-32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.py-4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.py-48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.py-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.py-\[11px\] {
    padding-top: 11px;
    padding-bottom: 11px;
}

.py-\[12px\] {
    padding-top: 12px;
    padding-bottom: 12px;
}

.pb-12 {
    padding-bottom: 12px;
}

.pb-16 {
    padding-bottom: 1rem;
}

.pb-20 {
    padding-bottom: 1.25rem;
}

.pb-32 {
    padding-bottom: 2rem;
}

.pb-72 {
    padding-bottom: 4.5rem;
}

.pl-16 {
    padding-left: 1rem;
}

.pl-20 {
    padding-left: 1.25rem;
}

.pl-24 {
    padding-left: 1.5rem;
}

.pr-16 {
    padding-right: 1rem;
}

.pr-20 {
    padding-right: 1.25rem;
}

.pr-24 {
    padding-right: 1.5rem;
}

.pr-32 {
    padding-right: 2rem;
}

.pr-48 {
    padding-right: 3rem;
}

.pr-64 {
    padding-right: 4rem;
}

.pr-8 {
    padding-right: 0.5rem;
}

.pt-16 {
    padding-top: 1rem;
}

.pt-20 {
    padding-top: 1.25rem;
}

.pt-24 {
    padding-top: 1.5rem;
}

.pt-8 {
    padding-top: 0.5rem;
}

.pt-\[10px\] {
    padding-top: 10px;
}

.pt-\[14px\] {
    padding-top: 14px;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.font-sans {
    font-family: var(--font-sans);
}

.text-12 {
    font-size: 0.75rem;
    line-height: 0.75rem;
}

.text-14 {
    font-size: 0.875rem;
    line-height: 1.125rem;
}

.text-16 {
    font-size: 1rem;
    line-height: 1.375rem;
}

.text-18 {
    font-size: 1.125rem;
    line-height: 1.5rem;
}

.text-18\/7 {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.text-24 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    letter-spacing: -0.5px;
}

.text-b12 {
    font-size: 0.75rem;
    line-height: 0.75rem;
    font-weight: 700;
}

.text-b14 {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 700;
}

.text-b16 {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 700;
}

.text-b18 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 700;
}

.text-b24 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    letter-spacing: -0.5px;
    font-weight: 700;
}

.text-b36 {
    font-size: 2.25rem;
    line-height: 2.625rem;
    letter-spacing: -1px;
    font-weight: 700;
}

.text-b48 {
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: -1px;
    font-weight: 700;
}

.text-b72 {
    font-size: 4.5rem;
    line-height: 4.5rem;
    letter-spacing: -2px;
    font-weight: 700;
}

.text-b88 {
    font-size: 5.5rem;
    line-height: 5.5rem;
    letter-spacing: -2px;
    font-weight: 700;
}

.font-bold {
    font-weight: 700;
}

.tabular-nums {
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-\[1\] {
    line-height: 1;
}

.leading-none {
    line-height: 1;
}

.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-current {
    color: currentColor;
}

.text-error {
    --tw-text-opacity: 1;
    color: rgb(237 67 43 / var(--tw-text-opacity));
}

.text-greyblue-40 {
    --tw-text-opacity: 1;
    color: rgb(159 159 163 / var(--tw-text-opacity));
}

.text-greyblue-55 {
    --tw-text-opacity: 1;
    color: rgb(124 124 129 / var(--tw-text-opacity));
}

.text-greyblue-70 {
    --tw-text-opacity: 1;
    color: rgb(87 87 94 / var(--tw-text-opacity));
}

.text-greyblue-85 {
    --tw-text-opacity: 1;
    color: rgb(51 51 60 / var(--tw-text-opacity));
}

.text-greyblue-95 {
    --tw-text-opacity: 1;
    color: rgb(28 28 37 / var(--tw-text-opacity));
}

.text-primary {
    --tw-text-opacity: 1;
    color: rgb(68 128 240 / var(--tw-text-opacity));
}

.text-success {
    --tw-text-opacity: 1;
    color: rgb(98 189 35 / var(--tw-text-opacity));
}

.text-transparent {
    color: transparent;
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
    text-decoration-line: underline;
}

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

.placeholder-white::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(255 255 255 / var(--tw-placeholder-opacity));
}

.placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(255 255 255 / var(--tw-placeholder-opacity));
}

.opacity-0 {
    opacity: 0;
}

.opacity-100 {
    opacity: 1;
}

.opacity-20 {
    opacity: 0.2;
}

.opacity-30 {
    opacity: 0.3;
}

.opacity-40 {
    opacity: 0.4;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-70 {
    opacity: 0.7;
}

.opacity-90 {
    opacity: 0.9;
}

.shadow-\[-4px_0_6px_5px_rgba\(0\2c 0\2c 0\2c 0\.21\)\] {
    --tw-shadow: -4px 0 6px 5px rgba(0,0,0,0.21);
    --tw-shadow-colored: -4px 0 6px 5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_-2px_4px_rgba\(0\2c 0\2c 0\2c 0\.16\)\] {
    --tw-shadow: 0 -2px 4px rgba(0,0,0,0.16);
    --tw-shadow-colored: 0 -2px 4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_0_1px_rgba\(159\2c 159\2c 163\2c 1\)\] {
    --tw-shadow: 0 0 0 1px rgba(159,159,163,1);
    --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_0_1px_rgba\(51\2c 51\2c 51\2c 0\.25\)\] {
    --tw-shadow: 0 0 0 1px rgba(51,51,51,0.25);
    --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_5px_rgba\(0\2c 0\2c 0\2c 0\.07\)\2c 0_5px_5px_rgba\(0\2c 0\2c 0\2c 0\.08\)\] {
    --tw-shadow: 0 0 5px rgba(0,0,0,0.07),0 5px 5px rgba(0,0,0,0.08);
    --tw-shadow-colored: 0 0 5px var(--tw-shadow-color), 0 5px 5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_2px_4px_0_rgba\(0\2c 0\2c 0\2c 0\.5\)\] {
    --tw-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    --tw-shadow-colored: 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_2px_4px_rgba\(0\2c 0\2c 0\2c 0\.5\)\] {
    --tw-shadow: 0 2px 4px rgba(0,0,0,0.5);
    --tw-shadow-colored: 0 2px 4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_5px_7px_1px_rgba\(0\2c 0\2c 0\2c 0\.32\)\] {
    --tw-shadow: 0 5px 7px 1px rgba(0,0,0,0.32);
    --tw-shadow-colored: 0 5px 7px 1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-details-panel {
    --tw-shadow: 0 2px 6px 0 rgb(0 0 0 / 0.62);
    --tw-shadow-colored: 0 2px 6px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-legend {
    --tw-shadow: 0 0 6px 0 rgb(0 0 0 / 0.07), 0 6px 6px 0 rgb(0 0 0 / 0.07);
    --tw-shadow-colored: 0 0 6px 0 var(--tw-shadow-color), 0 6px 6px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.drop-shadow {
    --tw-drop-shadow: drop-shadow(0 3px 7px rgb(0 0 0 / 0.32));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 250ms;
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 250ms;
}

.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 250ms;
}

.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 250ms;
}

.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 250ms;
}

.duration-150 {
    transition-duration: 150ms;
}

.\[writing-mode\:vertical-lr\] {
    writing-mode: vertical-lr;
}

/* Use Data Dashboard font for scroll hint text on google maps */

.gm-style-mot {
    font-family: var(--font-sans);
}

/* 
 * Google maps has a bug where it shows a blue focus-like border when interacting with the map. 
 * This bug has been marked as `fixed` in the google issue tracker, but as some other users report, it still exists. 
 * The following CSS removes the border from the affected element, making the focus-style virtually invisible. 
 * 
 * @see https://stackoverflow.com/questions/66669173/google-maps-js-api-shows-blue-focus-border
 * @see https://issuetracker.google.com/issues/182937497
 */

.gm-style iframe + div {
  border: none !important;
}

.placeholder\:text-greyblue-40::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(159 159 163 / var(--tw-text-opacity));
}

.placeholder\:text-greyblue-40::placeholder {
    --tw-text-opacity: 1;
    color: rgb(159 159 163 / var(--tw-text-opacity));
}

.before\:absolute::before {
    content: var(--tw-content);
    position: absolute;
}

.before\:left-0::before {
    content: var(--tw-content);
    left: 0px;
}

.before\:top-0::before {
    content: var(--tw-content);
    top: 0px;
}

.before\:h-full::before {
    content: var(--tw-content);
    height: 100%;
}

.before\:w-\[3px\]::before {
    content: var(--tw-content);
    width: 3px;
}

.before\:bg-greyblue-85::before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(51 51 60 / var(--tw-bg-opacity));
}

.placeholder-shown\:text-greyblue-40:-moz-placeholder-shown {
    --tw-text-opacity: 1;
    color: rgb(159 159 163 / var(--tw-text-opacity));
}

.placeholder-shown\:text-greyblue-40:placeholder-shown {
    --tw-text-opacity: 1;
    color: rgb(159 159 163 / var(--tw-text-opacity));
}

.focus-within\:bg-gradient-to-br:focus-within {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.focus-within\:from-gradient-start:focus-within {
    --tw-gradient-from: #00ADED var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 173 237 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.focus-within\:to-gradient-end:focus-within {
    --tw-gradient-to: #6468E7 var(--tw-gradient-to-position);
}

.hover\:z-10:hover {
    z-index: 10;
}

.hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:overflow-visible:hover {
    overflow: visible;
}

.hover\:text-clip:hover {
    text-overflow: clip;
}

.hover\:whitespace-normal:hover {
    white-space: normal;
}

.hover\:break-words:hover {
    overflow-wrap: break-word;
}

.hover\:border-greyblue-40:hover {
    --tw-border-opacity: 1;
    border-color: rgb(159 159 163 / var(--tw-border-opacity));
}

.hover\:border-greyblue-5:hover {
    --tw-border-opacity: 1;
    border-color: rgb(243 243 244 / var(--tw-border-opacity));
}

.hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-primary:hover {
    --tw-text-opacity: 1;
    color: rgb(68 128 240 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:opacity-100:hover {
    opacity: 1;
}

.hover\:shadow-\[0_0_0_1px_rgba\(51\2c 51\2c 51\2c \.5\)\]:hover {
    --tw-shadow: 0 0 0 1px rgba(51,51,51,.5);
    --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:border-primary:focus {
    --tw-border-opacity: 1;
    border-color: rgb(68 128 240 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
}

.group:hover .group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:fill-primary {
    fill: #4480F0;
}

.group:hover .group-hover\:text-primary {
    --tw-text-opacity: 1;
    color: rgb(68 128 240 / var(--tw-text-opacity));
}

.group\/iconHover:hover .group-hover\/iconHover\:opacity-0 {
    opacity: 0;
}

.group\/iconHover:hover .group-hover\/iconHover\:opacity-100 {
    opacity: 1;
}

.group:hover .group-hover\:opacity-0 {
    opacity: 0;
}

.aria-disabled\:pointer-events-none[aria-disabled="true"] {
    pointer-events: none;
}

.aria-disabled\:opacity-30[aria-disabled="true"] {
    opacity: 0.3;
}

.aria-invalid\:bg-error[aria-invalid="true"] {
    --tw-bg-opacity: 1;
    background-color: rgb(237 67 43 / var(--tw-bg-opacity));
}

.aria-invalid\:focus-within\:from-error:focus-within[aria-invalid="true"] {
    --tw-gradient-from: #ED432B var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(237 67 43 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.aria-invalid\:focus-within\:to-error:focus-within[aria-invalid="true"] {
    --tw-gradient-to: #ED432B var(--tw-gradient-to-position);
}

.data-\[state\=\'delayed-open\'\]\:text-white[data-state='delayed-open'] {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group[data-active=true] .group-data-\[active\=true\]\:visible {
    visibility: visible;
}

.group[data-active=true] .group-data-\[active\=true\]\:block {
    display: block;
}

.group\/trigger[data-state=open] .group-data-\[state\=open\]\/trigger\:rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group\/item[data-selected=true] .group-data-\[selected\=true\]\/item\:border {
    border-width: 1px;
}

.group[data-active='true'] .group-data-\[active\=\'true\'\]\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.group[data-active='true'] .group-data-\[active\=\'true\'\]\:text-primary {
    --tw-text-opacity: 1;
    color: rgb(68 128 240 / var(--tw-text-opacity));
}

.group\/item[data-selected=true] .group-data-\[selected\=true\]\/item\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group\/iconHover[data-active=true] .group-data-\[active\=true\]\/iconHover\:opacity-0 {
    opacity: 0;
}

.group\/iconHover[data-active=true] .group-data-\[active\=true\]\/iconHover\:opacity-100 {
    opacity: 1;
}

.group\/item[data-selected=true] .group-data-\[selected\=true\]\/item\:hover\:border-greyblue-5:hover {
    --tw-border-opacity: 1;
    border-color: rgb(243 243 244 / var(--tw-border-opacity));
}

@media (prefers-reduced-motion: no-preference) {

    @keyframes pulse {

        50% {
            opacity: .5;
        }
    }

    .motion-safe\:animate-pulse {
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }
}

@media (max-width: 1760px) {

    .max-\[1760px\]\:max-w-\[44px\] {
        max-width: 44px;
    }

    .max-\[1760px\]\:flex-col {
        flex-direction: column;
    }

    .max-\[1760px\]\:items-end {
        align-items: flex-end;
    }
}

@media (min-width: 640px) {

    .sm\:block {
        display: block;
    }

    .sm\:hidden {
        display: none;
    }
}

@media (min-width: 768px) {

    .md\:absolute {
        position: absolute;
    }

    .md\:sticky {
        position: sticky;
    }

    .md\:left-\[-15px\] {
        left: -15px;
    }

    .md\:top-24 {
        top: 1.5rem;
    }

    .md\:col-span-3 {
        grid-column: span 3 / span 3;
    }

    .md\:col-span-9 {
        grid-column: span 9 / span 9;
    }

    .md\:my-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .md\:mt-0 {
        margin-top: 0px;
    }

    .md\:h-72 {
        height: 4.5rem;
    }

    .md\:h-\[150px\] {
        height: 150px;
    }

    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .md\:gap-x {
        -moz-column-gap: 40px;
             column-gap: 40px;
    }

    .md\:border-0 {
        border-width: 0px;
    }

    .md\:border-l {
        border-left-width: 1px;
    }

    .md\:border-l-greyblue-20 {
        --tw-border-opacity: 1;
        border-left-color: rgb(207 207 209 / var(--tw-border-opacity));
    }

    .md\:px-\[105px\] {
        padding-left: 105px;
        padding-right: 105px;
    }

    .md\:text-b24 {
        font-size: 1.5rem;
        line-height: 1.75rem;
        letter-spacing: -0.5px;
        font-weight: 700;
    }

    .md\:text-b36 {
        font-size: 2.25rem;
        line-height: 2.625rem;
        letter-spacing: -1px;
        font-weight: 700;
    }

    .md\:text-b72 {
        font-size: 4.5rem;
        line-height: 4.5rem;
        letter-spacing: -2px;
        font-weight: 700;
    }
}

@media (min-width: 1024px) {

    .lg\:col-span-10 {
        grid-column: span 10 / span 10;
    }

    .lg\:col-start-2 {
        grid-column-start: 2;
    }

    .lg\:block {
        display: block;
    }

    .lg\:grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    .lg\:justify-between {
        justify-content: space-between;
    }
}

@media (min-width: 1280px) {

    .xl\:col-span-10 {
        grid-column: span 10 / span 10;
    }

    .xl\:col-span-11 {
        grid-column: span 11 / span 11;
    }

    .xl\:col-span-2 {
        grid-column: span 2 / span 2;
    }
}

@media (min-width: 1760px) {

    .min-\[1760px\]\:flex-row-reverse {
        flex-direction: row-reverse;
    }
}

.\[\&\>div\]\:\!block>div {
    display: block !important;
}

